import React, { useEffect } from "react";
import Seo from "@components/SEO";
import Layout from "@components/Layout";
import styled from "@emotion/styled";
import mediaqueries from "@styles/media";

const SearchPage = (props) => {
  useEffect(() => {
    var cx = "c5305f7d841a53139";
    var gcse = document.createElement("script");
    gcse.async = true;
    gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(gcse, s);
  });

  return (
    <Layout>
      <Seo />
      <Search>
        <div className="gcse-search"></div>
      </Search>
    </Layout>
  );
};

const Search = styled.div`
  margin:100px auto 120px;
  max-width: 680px;

  ${mediaqueries.desktop`
    max-width: calc(507px + 53px);
    margin: 100px auto 70px;
  `}

  ${mediaqueries.tablet`
    margin: 100px auto 70px;
    max-width: 480px;
  `}

  ${mediaqueries.phablet`
    margin: 100px auto 70px;
    padding: 0 40px;
  `}

  @media screen and (max-height: 700px) {
    margin: 100px auto;
  }
`;

export default SearchPage;
